import { Box, Heading, Link, useDisclosure } from '@chakra-ui/react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import React from 'react';

const containerStyle = {
    width: '100%',
    height: '100%',
};

const pinPisition = {
    lat: 50.29484694155094,
    lng: 19.029280719133904,
};

export const Map = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <LoadScript googleMapsApiKey="AIzaSyDIjRoE4s8WgUvaKTNU6IVNeunRZr_j2XQ">
            <GoogleMap mapContainerStyle={containerStyle} center={pinPisition} zoom={12}>
                {isOpen && (
                    <InfoWindow position={pinPisition} onCloseClick={onClose}>
                        <Box p="4">
                            <Link href="https://goo.gl/maps/pqwemXKpTSbcMU3Q7" target="_blank">
                                <Heading size="sm" as="h4">
                                    Otwórz w Google Maps
                                </Heading>
                            </Link>
                        </Box>
                    </InfoWindow>
                )}
                <Marker position={pinPisition} onClick={onOpen} />
            </GoogleMap>
        </LoadScript>
    );
};
