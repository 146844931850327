import { Box, Flex, Grid, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../@chakra-ui/gatsby-plugin/theme';
import Phone from '../../images/phone.svg';
import Address from '../../images/address.svg';
import Email from '../../images/email.svg';
import { Map } from '../Map';

export const ContactCard = () => {
    return (
        <Grid
            gridTemplateAreas={[
                `'info'
                'map'
                'nip'`,
                `'info map'
                'nip map'`,
            ]}
            gridTemplateColumns={[`1fr`, `1fr 2fr`]}
            alignSelf="stretch"
            mx={['2', '0']}
            px="6"
            py="6"
            gap="8"
            border="1px"
            borderRadius="xl"
            borderColor={theme.brandColors.border}
            backgroundColor={theme.brandColors.background}
            shadow="0px 6px 15px rgba(26, 27, 56, 0.25)"
        >
            <VStack spacing="6" align="flex-start" gridArea="info">
                <Flex direction="row" gap="4" alignItems="center">
                    <Icon w="8" h="auto" as={Phone as any} />
                    <Heading as="h3" size="md">
                        +48 501 008 220
                    </Heading>
                </Flex>
                <Flex direction="row" gap="4" alignItems="center">
                    <Icon w="8" h="auto" as={Email as any} />
                    <Heading as="h3" size="md">
                        biuro@silmet.pl
                    </Heading>
                </Flex>
                <Flex direction="row" gap="4" alignItems="start">
                    <Icon w="8" h="auto" as={Address as any} />
                    <Heading as="h3" size="md" whiteSpace="pre-line">
                        {`P.P. "Silmet"
                        ul. Składowa 9
                        41-100 Siemianowice Śląskie`}
                    </Heading>
                </Flex>
            </VStack>
            <Box h="400px" gridArea="map">
                <Map />
            </Box>
            <Box gridArea="nip">
                <Text fontSize={['12px', '16px']} whiteSpace="pre-line">
                    {`Dane do faktury:
                    Przedsiębiorstwo Produkcyjne "Silmet"
                    ul. Grabowa 6/5
                    41-100 Siemianowice Śląskie
                    NIP: 643-001-01-22`}
                </Text>
            </Box>
        </Grid>
    );
};
