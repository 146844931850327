import { Container, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ContactCard } from '../components/ContactCard';
import { Layout } from '../components/Layout';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = () => {
    return (
        <Layout title="Kontakt">
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <ContactCard />
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;
